import axios from "axios";
import React, { useEffect, useState } from "react";
import OTPInput from 'react-otp-input';
import { toastifyError } from "../Utility/Utility";
import { Link } from "react-router-dom";
import { tr } from "date-fns/locale";
// import logo from '../logo.jpg';
import logo from '../logo1.png'; 

const AstroRegister = () => {

    let timerId;
    const [errors, setErrors] = useState({});
    const [gender, setGender] = useState("Male");
    const [mobileOtp, setMobileOtp] = useState("");
    const [sendOtp, setSendOtp] = useState(false);
    const [sec, setSec] = useState("00");
    // console.log(sec, 'sec')
    const [min, setMin] = useState("00");
    const [timerOn, setTimerOn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [InsertStatus, setInsertStatus] = useState(false);
    const [value, setValue] = useState({
        'FirstName': '', 'LastName': '', 'EmailID': '', 'RegMobileNo': '', 'PrimaryMobileNo': '', 'DOB': '', 'TOB': '', 'POB': '', 'Faith': '', 'Languages': '',
        'skills': '', 'ExperiencedYears': '', 'Maritalstatus': '', 'Gender': '', 'AadharNo': '', 'PANCardNo': '', 'CurrentAddress': '', 'City': '', 'PINCode': '',
        'CreatedByUserID': '', 'IsHomePage': '', 'HighestQualification': '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const onChangeGender = (e) => {
        setGender(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!value.FirstName) newErrors.FirstName = "First Name is required.";
        if (!value.LastName) newErrors.LastName = "Last Name is required.";
        if (!value.City) newErrors.City = "City is required.";
        if (!value.ExperiencedYears) newErrors.ExperiencedYears = "Experienced is required.";
        if (!value.HighestQualification) newErrors.HighestQualification = "Qualification is required.";
        if (!value.EmailID) {
            newErrors.EmailID = "EmailID is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value?.EmailID)) {
            newErrors.EmailID = "Invalid EmailID address.";
        }
        if (!value.RegMobileNo) {
            newErrors.RegMobileNo = "Mobile number is required.";
        } else if (value?.RegMobileNo?.length !== 10) {
            newErrors.RegMobileNo = "Mobile number must be 10 digits.";
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            Check_Otp(mobileOtp);
            // handleOtpChange(mobileOtp)
        }
    };

    const startTimer = (duration) => {
        let remaining = duration;
        const countdown = () => {
            if (remaining <= 0) {
                setTimerOn(false);
                return;
            }
            const minutes = Math.floor(remaining / 60).toString().padStart(2, "0");
            const seconds = (remaining % 60).toString().padStart(2, "0");
            setMin(minutes);
            setSec(seconds);
            remaining -= 1;
        };
        countdown();
        timerId = setInterval(countdown, 1000);
    };

    useEffect(() => {
        if (timerOn) {
            startTimer(60);
        }
        return () => clearInterval(timerId);
    }, [timerOn]);

    useEffect(() => {
        if (sec === '01') {
            value.RegMobileNo = '';
            setSendOtp(false); setMobileOtp('');
        }
    }, [sec])

    useState(() => {
        sessionStorage.setItem('IsLogin', false);
    }, [])

    const HandleChangeInput = (e) => {
        if (e.target.name === 'ExperiencedYears') {
            let ele = e.target.value.replace(/[^0-9]/g, "");
            setValue({
                ...value,
                [e.target.name]: ele
            });
        } else if (e.target.name === 'RegMobileNo') {
            let ele = e.target.value.replace(/[^0-9]/g, "");
            setValue({ ...value, [e.target.name]: ele });
        }
    }

    const handleSendOtp = () => {
        if (value?.RegMobileNo?.length === 10) {
            Get_OTP();
            setSendOtp(true);
            setTimerOn(true);
        }
    };


    // const handleOtpChange = (otp) => {
    //     setMobileOtp(otp);
    //     if (otp?.length === 4) {
    //         Check_Otp(otp);
    //     } else {
    //         setErrors({ ...errors, mobileOtp: "Please enter a 4-digit OTP." });
    //     }
    // };

    const handleOtpChange = (otp) => {
        setMobileOtp(otp);
    };

    const reset = () => {
        setValue({
            ...value,
            'FirstName': '', 'LastName': '', 'EmailID': '', 'RegMobileNo': '', 'PrimaryMobileNo': '', 'DOB': '', 'TOB': '', 'POB': '', 'Faith': '', 'Languages': '',
            'skills': '', 'ExperiencedYears': '', 'Maritalstatus': '', 'Gender': '', 'AadharNo': '', 'PANCardNo': '', 'CurrentAddress': '', 'City': '', 'PINCode': '',
            'CreatedByUserID': '', 'IsHomePage': '', 'HighestQualification': ''
        });
    }

    const Get_OTP = async () => {
        const val = {
            'MobileNo': value?.RegMobileNo,
        };
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/SMS/GetData_SMS', val);
            if (res) {
                // console.log(res, 'res')
            }
        } catch (error) {
            console.log(error);
        }
    };


    // const Check_Otp = async (otp) => {
    //     const val = { 'MobileNo': value?.RegMobileNo, 'Otp': otp };
    //     try {
    //         const res = await axios.post('https://rmsapi2.arustu.com/api/SMS/Check_Otp', val);
    //         const { data } = res
    //         const parseData = JSON.parse(data?.data)
    //         const Resdata = parseData?.Table
    //         if (Resdata[0]?.Status === 'true' || Resdata[0]?.Status === true) {
    //             Get_astro();
    //         } else {
    //             // setSendOtp(true)
    //             toastifyError('Please enter the OTP');
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const Check_Otp = async (otp) => {
        if (!otp || otp.length < 4) {
            toastifyError('Please enter the OTP.');
            return;
        }

        const val = { 'MobileNo': value?.RegMobileNo, 'Otp': otp };

        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/SMS/Check_Otp', val);
            const { data } = res;

            const parseData = JSON.parse(data?.data);
            const Resdata = parseData?.Table;

            if (Resdata && Resdata[0]?.Status === 'true') {
                Get_astro();
            } else {
                toastifyError('Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                toastifyError('There was an issue verifying your OTP. Please try again later.');
            } else if (error.request) {
                toastifyError('Network error. Please check your internet connection.');
            } else {
                toastifyError('An unknown error occurred. Please try again later.');
            }
        }
    };


    const Get_astro = async () => {
        const val = { 'MobileNo': value?.RegMobileNo };
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/Astrologer/Astrologer_Login', val);
            const { data } = res;
            const parseData = JSON.parse(data?.data);
            const Resdata = parseData?.Table;
            if (Resdata[0]?.Astro === "1") {
                openModal();
                // value.RegMobileNo = '';
                // setSendOtp(false); setMobileOtp('');
            }
        } catch (error) {
            console.log(error, 'error');
            const { data } = error?.response;
            if (!data?.data) { Insert_Astrologer_Data(); }
        }
    };

    const Insert_Astrologer_Data = async () => {
        const { FirstName, LastName, EmailID, RegMobileNo, ExperiencedYears, Gender, City, HighestQualification } = value
        const val = { FirstName: FirstName, LastName: LastName, EmailID: EmailID, RegMobileNo: RegMobileNo, ExperiencedYears: ExperiencedYears, Gender: gender, City: City, HighestQualification: HighestQualification }
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/Astrologer/InsertAstrologerDetails', val)
            if (res) {
                OpenInsertStatus();
                reset();
                setSendOtp(false); setMobileOtp('');
            }
        } catch (errors) {
            console.log(errors)
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        reset();
        value.RegMobileNo = '';
        setGender('Male')
        setSendOtp(false); setMobileOtp('');
    };

    const OpenInsertStatus = () => {
        setInsertStatus(true);
        setTimeout(() => {
            setInsertStatus(false);
        }, 20000);
    };

    return (
        <>
            {/* <div>
                Reach More Clients, Anywhere in the World

                AstroCall.live eliminates geographical limitations, allowing you to serve clients globally from the comfort of your home.
                Whether you specialize in Vedic astrology, tarot reading, numerology, or other disciplines, the platform provides a global stage to showcase your expertise.
                AstroCall.live is not just a platform—it’s a professional ecosystem. By joining, astrologers gain access to tools and resources to enhance their career.
            </div>
            <div className="astro-register-container">
                <h2>AstroRegister Form</h2>
                <div className="astro-register-form" >
                    <div className="row">
                        <div className="mb-3 col-6">
                            <label className="form-label">First Name*</label>
                            <input
                                type="text"
                                name="FirstName"
                                value={value.FirstName}
                                onChange={handleChange}
                                className={`form-control ${errors.FirstName ? "is-invalid" : ""}`}
                            />
                            {errors.FirstName && <div className="invalid-feedback">{errors.FirstName}</div>}
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Last Name*</label>
                            <input
                                type="text"
                                name="LastName"
                                value={value.LastName}
                                onChange={handleChange}
                                className={`form-control ${errors.LastName ? "is-invalid" : ""}`}
                            />
                            {errors.LastName && <div className="invalid-feedback">{errors.LastName}</div>}
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Email Address*</label>
                            <input
                                type="text"
                                name="EmailID"
                                value={value?.EmailID}
                                onChange={handleChange}
                                className={`form-control ${errors.EmailID ? "is-invalid" : ""}`}
                            />
                            {errors.EmailID && <div className="invalid-feedback">{errors.EmailID}</div>}
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Qualification*</label>
                            <input
                                type="text"
                                name="HighestQualification"
                                value={value.HighestQualification}
                                onChange={handleChange}
                                className={`form-control ${errors.HighestQualification ? "is-invalid" : ""}`}
                            />
                            {errors.HighestQualification && <div className="invalid-feedback">{errors?.HighestQualification}</div>}
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Experience (Years)*</label>
                            <input
                                type="text"
                                name="ExperiencedYears"
                                value={value.ExperiencedYears}
                                maxLength={2}
                                onChange={HandleChangeInput}
                                className={`form-control ${errors.ExperiencedYears ? "is-invalid" : ""}`}
                            />
                            {errors.ExperiencedYears && <div className="invalid-feedback">{errors?.ExperiencedYears}</div>}
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label">Current City*</label>
                            <input
                                type="text"
                                name="City"
                                value={value?.City}
                                onChange={handleChange}
                                className={`form-control ${errors.City ? "is-invalid" : ""}`}
                            />
                            {errors.City && <div className="invalid-feedback">{errors?.City}</div>}
                        </div>


                        <div className="mb-3">
                            <label className="form-label">Gender</label>
                            <div className="d-flex">
                                <div className="form-check me-3">
                                    <input type="radio" className="form-check-input" name="gender" value="Male" checked={gender === "Male"} onChange={onChangeGender} />
                                    <label className="form-check-label">Male</label>
                                </div>
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" name="gender" value="Female" checked={gender === "Female"} onChange={onChangeGender} />
                                    <label className="form-check-label">Female</label>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Mobile*</label>
                            <input
                                type="text"
                                name="RegMobileNo"
                                value={value?.RegMobileNo}
                                onChange={HandleChangeInput}
                                maxLength={10}
                                className={`form-control ${errors.RegMobileNo ? "is-invalid" : ""}`}
                            />
                            {errors.RegMobileNo && <div className="invalid-feedback">{errors?.RegMobileNo}</div>}
                            <button
                                type="button"
                                className="btn btn-secondary mt-2"
                                onClick={handleSendOtp}
                                disabled={value?.RegMobileNo?.length !== 10}
                            >
                                Send OTP
                            </button>
                        </div>
                        {sendOtp && (
                            <div className="mb-3">
                                <OTPInput
                                    value={mobileOtp}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    separator={<span>--</span>}
                                    inputStyle={{ width: '3.5rem', height: '3.5rem', margin: '0px 5px', border: '1px solid #ccc' }}
                                    isInputNum
                                    renderInput={(props) => <input {...props} />}
                                />
                                {errors.mobileOtp && <div className="invalid-feedback">{errors.mobileOtp}</div>}
                                <div style={{ fontSize: "13px" }} className="py-3"> OTP Expires in: {min}:{sec}</div>
                            </div>
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={validateForm}>Register</button>
                </div>
                <>
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h4>This Number Already Exists </h4>
                                <button onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    )}
                </>
                <>
                    {InsertStatus && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <p>Connect With ShortList</p>
                            </div>
                        </div>
                    )}
                </>

                </div> */}
            <div className="d-flex justify-content-between">
                <img src={logo} alt="" className="logo-img" />
                <h4 className="text-center mx-auto mt-3 "><strong style={{  background: 'blanchedalmond', padding: '8px', borderRadius: '10px', boxShadow: '0px 2px 4px gray' }}>Pre Registraion Form</strong></h4>
                {/* <img src={logo} alt="" className="logo-img" /> */}

            </div>
            <div class="container " >
                <div class="row justify-content-center">
                    <div className="col-md-6 mb-4 order-2 order-md-1" style={{ marginTop: '-3%' }}>
                        <h3 className="bold-heading">"Reach More Clients, Anywhere in the World" <strong style={{ color: '#ff6600' }}>Join Now!</strong></h3>
                        <ul className="bullet-points mt-3">
                            <li>AstroCall.live eliminates geographical limitations, allowing you to serve clients globally from the comfort of your home.</li>
                            <li>Whether you specialize in Vedic astrology, tarot reading, numerology, or other disciplines, the platform provides a global stage to showcase your expertise.</li>
                            <li>AstroCall.live is not just a platform—it’s a professional ecosystem. By joining, astrologers gain access to tools and resources to enhance their career.</li>
                        </ul>
                        {/* <h3 className="bold-heading">"दुनिया में कहीं भी, अधिक ग्राहकों तक पहुंचें" <strong style={{ color: '#ff6600' }}>अब शामिल हों!</strong></h3>
                        <ul className="bullet-points mt-3">
                            <li>एस्ट्रोकॉल.लाइव भौगोलिक सीमाओं को समाप्त करता है, जिससे आप अपने घर के आराम से वैश्विक स्तर पर ग्राहकों को सेवा दे सकते हैं।</li>
                            <li>चाहे आप वैदिक ज्योतिष, टैरो रीडिंग, अंकशास्त्र, या अन्य विषयों में विशेषज्ञ हों, यह मंच आपकी विशेषज्ञता प्रदर्शित करने के लिए एक वैश्विक मंच प्रदान करता है।</li>
                            <li>एस्ट्रोकॉल.लाइव सिर्फ एक मंच नहीं है - यह एक पेशेवर पारिस्थितिकी तंत्र है। इसमें शामिल होने से, ज्योतिषी अपने करियर को बढ़ाने के लिए उपकरणों और संसाधनों तक पहुंच प्राप्त करते हैं।</li>
                        </ul> */}
                        <div className="contact-us-section " style={{ backgroundColor: '#f8f9fa', padding: 10, borderRadius: 8, boxShadow: '0px 1px 5px #ff6600' }}>
                            <h5 className="contact-heading" style={{ fontSize: '20px', color: '#ff6600', fontWeight: 'bold' }}>Contact Us</h5>

                            <div className="contact-details" style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 8 }}>
                                <i className="fa fa-phone" style={{ color: '#ff6600', fontSize: '1.5rem' }} />
                                <span style={{ fontSize: '1.1rem', color: '#333', fontWeight: 500 }}>7229810003</span>
                            </div>
                            <p style={{ marginTop: 12, fontSize: '0.9rem', color: '#555', fontWeight: 600 }}>Feel free to reach out with any questions or to get started with AstroCall.live. Our team is here to assist you!</p>
                        </div>
                    </div>


                    <div class=" col-md-6 order-1 order-md-2  " style={{ marginTop: '-3%' }}>
                        <h3 className="text-center">Astrologer Sign Up</h3>
                        <div class="astro-register-form form-container">
                            <div class="row">
                                <div class=" col-md-6">
                                    <label class="form-label">First Name*</label>
                                    <input type="text" name="FirstName" value={value.FirstName} onChange={handleChange} className={`form-control ${errors.FirstName ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.FirstName && <div className="invalid-feedback">{errors.FirstName}</div>}
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Last Name*</label>
                                    <input type="text" name="LastName" value={value.LastName} onChange={handleChange} className={`form-control ${errors.LastName ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.LastName && <div className="invalid-feedback">{errors.LastName}</div>}
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Email Address*</label>
                                    <input type="text" name="EmailID" value={value?.EmailID} onChange={handleChange} className={`form-control ${errors.EmailID ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.EmailID && <div className="invalid-feedback">{errors.EmailID}</div>}
                                </div>
                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Qualification*</label>
                                    <input type="text" name="HighestQualification" value={value.HighestQualification} onChange={handleChange} className={`form-control ${errors.HighestQualification ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.HighestQualification && <div className="invalid-feedback">{errors?.HighestQualification}</div>}
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Experience (Years)*</label>
                                    <input type="text" name="ExperiencedYears" value={value.ExperiencedYears} maxLength={2} onChange={HandleChangeInput} className={`form-control ${errors.ExperiencedYears ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.ExperiencedYears && <div className="invalid-feedback">{errors?.ExperiencedYears}</div>}
                                </div>
                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Current Address*</label>
                                    <input type="text" name="City" value={value?.City} onChange={handleChange} className={`form-control ${errors.City ? "is-invalid" : ""}`} autoComplete="off" />
                                    {errors.City && <div className="invalid-feedback">{errors?.City}</div>}
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Gender</label>
                                    <div class="d-flex">
                                        <div class="form-check me-3">
                                            <input type="radio" className="form-check-input" name="gender" value="Male" checked={gender === "Male"} onChange={onChangeGender} />
                                            <label className="form-check-label">Male</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="radio" className="form-check-input" name="gender" value="Female" checked={gender === "Female"} onChange={onChangeGender} autoComplete="off" />
                                            <label className="form-check-label">Female</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 col-md-6">
                                    <label class="form-label">Mobile*</label>
                                    <input type="text" name="RegMobileNo" value={value?.RegMobileNo} onChange={HandleChangeInput} maxLength={10} className={`form-control ${errors.RegMobileNo ? "is-invalid" : ""}`} />
                                    {errors.RegMobileNo && <div className="invalid-feedback">{errors?.RegMobileNo}</div>}
                                    <button type="button" className="btn btn-secondary mt-2" onClick={handleSendOtp} disabled={value?.RegMobileNo?.length !== 10} style={{
                                        backgroundColor: value?.RegMobileNo?.length === 10 ? "#ff6600" : "#6c757d",
                                        color: "#fff"
                                    }}>Send OTP</button>
                                </div>

                                {sendOtp && (
                                    <div className="mb-2 col-md-6">
                                        <OTPInput
                                            value={mobileOtp}
                                            onChange={handleOtpChange}
                                            numInputs={4}
                                            separator={<span>--</span>}
                                            inputStyle={{ width: '3.5rem', height: '3.5rem', margin: '0px 5px', border: '1px solid #ccc' }}
                                            isInputNum
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        {errors.mobileOtp && <div className="invalid-feedback">{errors.mobileOtp}</div>}
                                        <div style={{ fontSize: "13px" }} className="py-1">OTP Expires in: {min}:{sec}</div>
                                    </div>
                                )}

                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary" onClick={validateForm}>Register</button>
                                </div>
                            </div>
                        </div>

                        {isModalOpen && (
                            <div className="modal-overlay">
                                <div className="modal-content">
                                    <h4 >This Number Already Exists </h4>
                                    <button onClick={closeModal}>Close</button>
                                </div>
                            </div>
                        )}
                        {InsertStatus && (
                            <div className="modal-overlay">
                                <div className="modal-content" >
                                    <h4 style={{ color: '#ff6600' }}>We Will Connect With You Shortly</h4>
                                    <p style={{ fontFamily: "serif", fontSize: '16px', fontWeight: "600" }}>Thank you for your patience. Our team will reach out to you as soon as possible.</p>
                                </div>
                            </div>
                        )}

                        {/* 
                        {InsertStatus && (
                            <div className="modal-overlay">
                                <div className="modal-content">
                                    <h5>Connect With You Shortly</h5>
                                </div>
                            </div>
                        )} */}
                        {/* <div className="astro-register-container ">
                            <h2>AstroRegister Form</h2>
                            <div className="astro-register-form" >
                                <div className="row">
                                    <div className="mb-3 col-6">
                                        <label className="form-label">First Name*</label>
                                        <input
                                            type="text"
                                            name="FirstName"
                                            value={value.FirstName}
                                            onChange={handleChange}
                                            className={`form-control ${errors.FirstName ? "is-invalid" : ""}`}
                                        />
                                        {errors.FirstName && <div className="invalid-feedback">{errors.FirstName}</div>}
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Last Name*</label>
                                        <input
                                            type="text"
                                            name="LastName"
                                            value={value.LastName}
                                            onChange={handleChange}
                                            className={`form-control ${errors.LastName ? "is-invalid" : ""}`}
                                        />
                                        {errors.LastName && <div className="invalid-feedback">{errors.LastName}</div>}
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Email Address*</label>
                                        <input
                                            type="text"
                                            name="EmailID"
                                            value={value?.EmailID}
                                            onChange={handleChange}
                                            className={`form-control ${errors.EmailID ? "is-invalid" : ""}`}
                                        />
                                        {errors.EmailID && <div className="invalid-feedback">{errors.EmailID}</div>}
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Qualification*</label>
                                        <input
                                            type="text"
                                            name="HighestQualification"
                                            value={value.HighestQualification}
                                            onChange={handleChange}
                                            className={`form-control ${errors.HighestQualification ? "is-invalid" : ""}`}
                                        />
                                        {errors.HighestQualification && <div className="invalid-feedback">{errors?.HighestQualification}</div>}
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Experience (Years)*</label>
                                        <input
                                            type="text"
                                            name="ExperiencedYears"
                                            value={value.ExperiencedYears}
                                            maxLength={2}
                                            onChange={HandleChangeInput}
                                            className={`form-control ${errors.ExperiencedYears ? "is-invalid" : ""}`}
                                        />
                                        {errors.ExperiencedYears && <div className="invalid-feedback">{errors?.ExperiencedYears}</div>}
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Current City*</label>
                                        <input
                                            type="text"
                                            name="City"
                                            value={value?.City}
                                            onChange={handleChange}
                                            className={`form-control ${errors.City ? "is-invalid" : ""}`}
                                        />
                                        {errors.City && <div className="invalid-feedback">{errors?.City}</div>}
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-label">Gender</label>
                                        <div className="d-flex">
                                            <div className="form-check me-3">
                                                <input type="radio" className="form-check-input" name="gender" value="Male" checked={gender === "Male"} onChange={onChangeGender} />
                                                <label className="form-check-label">Male</label>
                                            </div>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" name="gender" value="Female" checked={gender === "Female"} onChange={onChangeGender} />
                                                <label className="form-check-label">Female</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Mobile*</label>
                                        <input
                                            type="text"
                                            name="RegMobileNo"
                                            value={value?.RegMobileNo}
                                            onChange={HandleChangeInput}
                                            maxLength={10}
                                            className={`form-control ${errors.RegMobileNo ? "is-invalid" : ""}`}
                                        />
                                        {errors.RegMobileNo && <div className="invalid-feedback">{errors?.RegMobileNo}</div>}
                                        <button
                                            type="button"
                                            className="btn btn-secondary mt-2"
                                            onClick={handleSendOtp}
                                            disabled={value?.RegMobileNo?.length !== 10}
                                        >
                                            Send OTP
                                        </button>
                                    </div>
                                    {sendOtp && (
                                        <div className="mb-3">
                                            <OTPInput
                                                value={mobileOtp}
                                                onChange={handleOtpChange}
                                                numInputs={6}
                                                separator={<span>--</span>}
                                                inputStyle={{ width: '3.5rem', height: '3.5rem', margin: '0px 5px', border: '1px solid #ccc' }}
                                                isInputNum
                                                renderInput={(props) => <input {...props} />}
                                            />
                                            {errors.mobileOtp && <div className="invalid-feedback">{errors.mobileOtp}</div>}
                                            <div style={{ fontSize: "13px" }} className="py-3"> OTP Expires in: {min}:{sec}</div>
                                        </div>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={validateForm}>Register</button>
                            </div>
                            <>
                                {isModalOpen && (
                                    <div className="modal-overlay">
                                        <div className="modal-content">
                                            <h4>This Number Already Exists </h4>
                                            <button onClick={closeModal}>Close</button>
                                        </div>
                                    </div>
                                )}
                            </>
                            <>
                                {InsertStatus && (
                                    <div className="modal-overlay">
                                        <div className="modal-content">
                                            <p>Connect With ShortList</p>
                                        </div>
                                    </div>
                                )}
                            </>

                        </div>  */}
                    </div>
                </div>

            </div>
            <footer className="bg-body-tertiary text-center text-lg-start" style={{ position: 'fixed', bottom: '0px', width: '100%' }}>
                <div
                    className="text-center p-2"
                    style={{
                        backgroundColor: '#ff6600',
                        fontWeight: 'bold',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    <span style={{ fontSize: '16px' }}>
                        © 2021 Copyright :
                    </span>
                    <Link
                        to={'https://arustu.com/'}
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'white',
                            textDecoration: 'none'
                        }}

                    >
                        Arustu Technology
                    </Link>
                    <span style={{ fontSize: '16px' }}>
                        All right Reserved
                    </span>
                </div>
            </footer>


        </>
    );
};

export default AstroRegister;
