import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toastifyError, toastifySuccess } from '../Utility/Utility';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';

// Modal for editing astrologer data
const EditModal = ({ show, onClose, astrologer, onSave }) => {
    const [AstrologerData, setAstrologerData] = useState(astrologer);

    useEffect(() => {
        setAstrologerData(astrologer); // Reset form when astrologer changes
    }, [astrologer]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAstrologerData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        setAstrologerData((prev) => ({
            ...prev,
            Completed: checked,
        }));
    };

    const handleSave = () => {
        onSave(AstrologerData);
        onClose();
    };

    return (
        <>

            <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none', background: "rgba(0,0,0, 0.5)" }} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div className="modal-content" style={{ padding: '0px' }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="editModalLabel">Edit Astrologer</h5>
                            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                        </div>
                        <div className="mt-2">
                            <div className=" form-check">
                                <label className="form-label" htmlFor="flexCheckDefault" style={{ fontSize: "20px", padding: '0px 10px' }}>Complete</label>
                                <input
                                    type="checkbox"
                                    className="form-check-label"
                                    name="Completed"
                                    checked={AstrologerData?.Completed || false}
                                    onChange={handleCheckboxChange}
                                    id="flexCheckDefault"
                                />
                            </div>
                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const AstrologerList = () => {
    const isLogin = sessionStorage.getItem('IsLogin') ? sessionStorage.getItem('IsLogin') === 'true' || sessionStorage.getItem('IsLogin') === true : false;
    const [value, setvalue] = useState({ UserID: '', password: '' });
    const [error, setErrors] = useState('');
    const [AstrologerData, setAstrologerdata] = useState();
    const [selectedAstrologer, setSelectedAstrologer] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [originalAstrologerData, setOriginalAstrologerData] = useState([]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setvalue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        Get_Data_astrologer()
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            checkValidationErrors();
        }
    };

    const checkValidationErrors = () => {
        const newErrors = {};
        if (!value?.UserID) {
            newErrors.UserID = 'required *';
        } else if (value?.UserID !== 'Admin') {
            newErrors.UserID = 'Check UserID *';
        }
        if (!value?.password) {
            newErrors.password = 'required *';
            // } else if (value?.password !== '12345') {
        } else if (value?.password !== 'MS79@arustu624MP') {
            newErrors.password = 'Check Password *';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            handleSubmit();
        }
    };

    const handleSubmit = (e) => {
        if (value?.UserID === 'Admin' || value?.password === '12345') {
            sessionStorage.setItem('IsLogin', true);
            toastifySuccess("Successfully LogIn");
        } else {
            toastifyError('Check UserID And Password')
        }
    };

    const Get_Data_astrologer = async () => {
        const val = { 'IsActive': '1' };
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/Astrologer/GetData_Astrologer', val);
            const { data } = res;
            const parseData = JSON.parse(data?.data);
            const Resdata = parseData?.Table;
            if (Resdata) {
                setAstrologerdata(Resdata);
                setOriginalAstrologerData(Resdata);
            }
        } catch (error) {
            console.log(error, 'error');
        }
    };

    const openEditModal = (astrologer) => {
        setSelectedAstrologer(astrologer);
        setShowModal(true);
    };
    // console.log(selectedAstrologer)
    const saveAstrologerData = async (AstrologerData) => {
        const requestData = {
            AstrologerID: AstrologerData.ID,
            Completed: AstrologerData.Completed ? 1 : 0,
            ModifiedByUser: '',
        };
        console.log(AstrologerData.Completed)
        try {
            const res = await axios.post('https://rmsapi2.arustu.com/api/Astrologer/UpdateAstrologerCompleted', requestData);

            const { data } = res;
            const parseData = JSON.parse(data?.data);
            const Resdata = parseData?.Table;
            if (Resdata) {
                console.log(Resdata)
                toastifySuccess('Astrologer updated successfully');
                setAstrologerdata((prevData) =>
                    prevData.map((item) =>
                        item.ID === AstrologerData.ID ? { ...item, Completed: AstrologerData.Completed } : item
                    )
                );
            } else {
                toastifyError('Failed to update astrologer');
            }
        } catch (error) {
            console.log('Error updating astrologer:', error);
            toastifyError('An error occurred while updating astrologer');
        }
    };

    const TotalAstrologer = AstrologerData?.length
    const [selectedOption, setSelectedOption] = useState("");
    const options = ["Show All Astrologer", "Complete", "Not Complete"];

    const handleOptionChange = (e) => {
        const selected = e.target.value;
        setSelectedOption(selected);

        let filtered = [];
        if (selected === "Show All Astrologer") {
            filtered = originalAstrologerData; // Show all without filtering
        } else if (selected === "Complete") {
            filtered = originalAstrologerData?.filter((item) => item?.Completed === true);
        } else if (selected === "Not Complete") {
            filtered = originalAstrologerData?.filter((item) => item?.Completed === false);
        }

        setAstrologerdata(filtered);
    };


    return (
        <>
            {isLogin ? (
                <div>
                    <div className="container-fulid mt-5 px-5">

                        <div className="flex">
                            <div className='gap-3 p-2 rounded-2xl' style={{ backgroundColor: '#595959', width: '18em', height: '3em', alignItems: 'center', textAlign: 'center', justifyContent: 'center', color: 'white' }}>
                                <h4><strong>Total Astrologers:</strong>{TotalAstrologer}</h4>
                                {/* {/ <div className="flex items-start w-[200px] gap-3 p-4 rounded-xl bg-orange-300 shadow-sm"> /} */}
                                <div className="mt-3">
                                    <select
                                        className="form-select"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        {options.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <h1 className="text-center ">Astrologer List</h1>
                        </div>

                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Number</th>
                                    <th>DisplayName</th>
                                    <th>RegMobileNo</th>
                                    <th>EmailID</th>
                                    <th>Qualification</th>
                                    <th>Gender</th>
                                    <th>Experience (Years)</th>
                                    <th>Created Date</th>
                                    <th>City</th>
                                    <th>Status/Done</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AstrologerData?.map((item, index) =>
                                (
                                    <tr key={item?.id}>
                                        <td>{index + 1}</td>
                                        <td>{item?.DisplayName}</td>
                                        <td>{item?.RegMobileNo}</td>
                                        <td>{item?.EmailID}</td>
                                        <td>{item?.HighestQualification}</td>
                                        <td>{item?.Gender}</td>
                                        <td>{item?.ExperiencedYears}</td>
                                        <td>{format(new Date(item?.CreatedDtTm), 'MMMM d, yyyy')}</td>
                                        <td>{item?.City}</td>
                                        <td>{item?.Completed ? 'Complete' : 'Not Complete'}</td>
                                        <td>
                                            <button className="btn btn-warning" onClick={() => openEditModal(item)}>
                                                <i className="bi bi-pencil"></i> Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* {/ Edit Modal /} */}
                    <EditModal
                        show={showModal}
                        onClose={() => setShowModal(false)}
                        astrologer={selectedAstrologer}
                        onSave={saveAstrologerData}
                    />
                </div>
            ) : (
                <div className="container d-flex justify-content-center align-items-center vh-100">
                    <div className="card p-4 shadow-sm" style={{ width: '350px' }}>
                        <h3 className="text-center mb-4">Login</h3>
                        <div className="mb-3">
                            <label htmlFor="UserID" className="form-label">UserID</label>
                            <input
                                type="text"
                                className="form-control"
                                id="UserID"
                                name="UserID"
                                value={value?.UserID}
                                onChange={handleChange}
                                placeholder="Enter your UserID"
                            />
                            {error.UserID && <p style={{ color: 'red' }}>{error.UserID}</p>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                type="text"
                                className="form-control"
                                id="password"
                                name="password"
                                value={value?.password}
                                onChange={handleChange}
                                maxLength={16}
                                placeholder="Enter your password"
                            />
                            {error.password && <p style={{ color: 'red' }}>{error.password}</p>}
                        </div>
                        <button type="submit" className="btn btn-primary w-100" onClick={checkValidationErrors}>Login</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default AstrologerList;
